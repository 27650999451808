<template>
  <div class="report_work">
    <van-nav-bar
      title="新建报工"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <scroll class="report_work_scroll" :onLoadMore="onLoadMore">
      <van-form @submit="onSubmit" class="report_work_from">
        <div class="report_work_from_header">
          <van-field
            v-model="woCode"
            readonly
            required
            clickable
            name="工单编号"
            label="工单编号"
            placeholder="请选择工单编号"
            @click.stop="woCodeShow = true"
            right-icon="scan"
            @click-right-icon.stop="scanCode"
          >
          </van-field>
          <van-field
            v-model="wpName"
            readonly
            required
            clickable
            name="工序名称"
            label="工序名称"
            placeholder="请选择工序名称"
            @click="headleWpNameShow"
          />
          <van-field
            v-model="productName"
            required
            readonly
            name="产品名称"
            label="产品名称"
            placeholder="请输入"
          />
        </div>
        <div class="report_work_input">报工录入</div>
        <div class="report_work_input_details">
          <van-field
            class="report_work_personnel"
            v-model="personnel"
            required
            readonly
            name="生产人员"
            label="生产人员"
            placeholder="点击选择生产人员"
            @click="headleItemDefinePersonnel"
          />
          <div class="report_work_numb">
            <van-field
              v-model="goodProductCounts"
              required
              type="digit"
              name="良品数"
              label="良品数"
              placeholder="请输入"
            />
            <van-field
              v-model="total"
              readonly
              type="digit"
              name="总数"
              label="总数"
              placeholder="总数"
            />
            <div :model="badItem" class="div-bad">
              <div class="bad-item">
                <van-row :gutter="24" align="center">
                  <van-col :span="2">
                    <van-icon name="close" color="#F5F5F5" />
                  </van-col>
                  <van-col :span="10">不良品数</van-col>
                  <van-col :span="11">不良品项列表</van-col>
                </van-row>
              </div>
              <div
                v-for="(ed, index) in editRowInfo"
                :key="index"
                style="margin-top: 0.5%"
              >
                <van-row :gutter="24" align="center">
                  <van-col :span="2" class="col-pad">
                    <van-icon
                      name="close"
                      color="#ee0a24"
                      @click="removeTemplate(index)"
                    />
                  </van-col>
                  <van-col :span="10" class="col-info">
                    <van-field
                      v-model="ed.badProductCounts"
                      type="digit"
                      name="不良品数"
                      placeholder="请输入不良品数"
                    />
                  </van-col>
                  <span style="display: none">{{ (ed.index = index) }}</span>
                  <van-col :span="11" class="col-info">
                    <van-field
                      v-model="ed.name"
                      readonly
                      clickable
                      name="不良品数项"
                      placeholder="选择不良品项"
                      @click="headleItemDefective(index)"
                    />
                    <van-popup
                      v-model="defectiveShow"
                      position="bottom"
                      round
                      class="jurisdiction"
                      :style="{ height: '30%' }"
                    >
                      <van-picker
                        ref="van_pick"
                        show-toolbar
                        value-key="defectiveName"
                        :columns="defectiveItemObjList"
                        @cancel="defectiveShow = false"
                        @confirm="selectDefective"
                      />
                    </van-popup>
                  </van-col>
                </van-row>
              </div>
              <div class="icon-div-info">
                <van-row @click="addTemplate()">
                  <van-col :span="2">
                    <van-icon name="add-o" class="icon-two" />
                  </van-col>
                  <van-col :span="5"
                    ><span class="icon-add-text">添加一行</span></van-col
                  >
                </van-row>
              </div>
            </div>
          </div>
        </div>
        <div class="report_work_input_time">
          <van-field
            v-model="planStartTime"
            readonly
            clickable
            label="开始时间"
            left-icon=" icon icon-bitian"
            placeholder="点击选择时间"
            @click="startTimeShow = true"
          />
          <van-field
            v-model="planEndTime"
            readonly
            clickable
            label="结束时间"
            left-icon=" icon icon-bitian"
            placeholder="点击选择时间"
            @click="endTimeShow = true"
          />
        </div>
        <div class="report_work_achievements">
          <div class="report_work_achievements_item">
            <div>工资绩效</div>
            <div class="refresh" @click="headleItemRefresh">
              <span class="icon icon-shuaxin"></span>刷新
            </div>
          </div>
        </div>
        <div class="report_work_calculation">
          <div v-if="achieveList.type == 0">
            <div class="report_work_wages">
              <span>计件工价:</span>
              <span>{{ achieveList.price }}</span>
              <span class="icon icon-qiehuan"></span>
              <span>{{ goodProductCounts }}</span>
            </div>
            <div class="report_work_total">
              <span>计件工资:</span>
              <span class="report_work_total_yuan">{{ totalPrice }}元</span>
            </div>
          </div>
          <div v-else-if="achieveList.type == 1">
            <div class="report_work_wages">
              <span>计时工价:</span>
              <span>{{ achieveList.price }}</span>
              <span class="icon icon-qiehuan"></span>
              <span></span>
            </div>
            <div class="report_work_total">
              <span>计时工资:</span>
              <span class="report_work_total_yuan">{{ totalPrice }}元</span>
            </div>
          </div>
          <div v-else>
            <div class="report_work_wages">
              <span>计件工价:</span>
              <span>0</span>
              <span class="icon icon-qiehuan"></span>
            </div>
            <div class="report_work_total">
              <span>计件工资:</span>
            </div>
          </div>
        </div>
        <customise ref="customise" :defType="defType"></customise>
        <div class="report_work_input">审批</div>
        <van-field
          class="report_work_approval"
          v-model="roleName"
          readonly
          required
          clickable
          name="审批人员"
          label="审批人员"
          placeholder="点击选择审批人员"
          @click="approvalShow = true"
        />
        <van-popup
          v-model="approvalShow"
          position="bottom"
          round
          :close-on-click-overlay="false"
          class="jurisdiction"
        >
          <van-picker
            show-toolbar
            :columns="approvaRoleNameList"
            @cancel="approvalShow = false"
            @confirm="onApprovalConfirm"
          />
        </van-popup>
        <div style="margin: 30px 16px 16px 16px" class="submit">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </scroll>
    <van-popup
      v-model="startTimeShow"
      :close-on-click-overlay="false"
      position="bottom"
    >
      <van-datetime-picker
        type="datetime"
        @confirm="selectStartTime"
        @cancel="startTimeShow = false"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-popup>
    <van-popup
      v-model="woCodeShow"
      round
      is-link
      readonly
      class="jurisdiction"
      position="bottom"
    >
      <van-picker
        show-toolbar
        :columns="woCodeColumns"
        @cancel="woCodeShow = false"
        @confirm="onWoCodeConfirm"
      />
    </van-popup>
    <van-popup
      v-model="wpNameShow"
      position="bottom"
      round
      :close-on-click-overlay="false"
      class="jurisdiction"
    >
      <van-picker
        show-toolbar
        :columns="wpNameColumns"
        @cancel="wpNameShow = false"
        @confirm="onWpNameConfirm"
      />
    </van-popup>
    <van-popup
      v-model="endTimeShow"
      :close-on-click-overlay="false"
      position="bottom"
    >
      <van-datetime-picker
        type="datetime"
        @confirm="selectEndTime"
        @cancel="endTimeShow = false"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-popup>

    <van-popup
      v-model="personnelShow"
      position="bottom"
      round
      :close-on-click-overlay="false"
      :style="{ height: '30%' }"
      class="jurisdiction"
    >
      <div class="jurisdiction_headle">
        <div @click="personnelShow = false">取消</div>
        <div @click="personnelShow = false">确定</div>
      </div>
      <van-radio-group v-model="personnel">
        <van-radio
          :name="item.nickname"
          v-for="item in rwPowerOojList"
          :key="item.id"
          >{{ item.nickname }}
        </van-radio>
      </van-radio-group>
    </van-popup>
  </div>
</template>
<script>
import scroll from "../../components/scroll/index";
import { ChangePageTitle } from "../../common/js/upTitle";
import wx from "weixin-js-sdk";
import customise from "@/components/customise/index";
import { mapGetters } from "vuex";
import {
  getEwoWorkOrderList,
  getProcedureList,
  getEwoAchieveConfigParam,
  getSysUserList,
  addWork,
  getSignature,
} from "@/api/work.js";

export default {
  computed: {
    ...mapGetters(["workIngId", "departmentCode"]),
    minDate() {
      let curDate = new Date().getTime();
      let one = 7 * 24 * 3600 * 1000;
      let oneYear = curDate - one;
      return new Date(oneYear);
    },
  },
  watch: {
    planEndTime: {
      handler: function (val) {
        var _that = this;
        if (val) {
          if (_that.planStartTime > val) {
            _that.planEndTime = "";
            _that.$toast.fail("结束时间小于开始时间，请重新选择");
          }
        }
      },
    },
    editRowInfo: {
      handler(val, oldVal) {
        if (val.length > 0) {
          this.badProductCounts = val
            .map((i) => Number(i.badProductCounts))
            .reduce((preValue, n) => preValue + n);
        }
      },
      deep: true,
    },
    goodProductCounts: function (val) {
      if (val == 0) {
        this.totalPrice = 0;
        this.total = parseInt(this.badProductCounts);
      } else {
        this.total =
          parseInt(this.badProductCounts) + parseInt(this.goodProductCounts);
        this.totalPrice = val * this.achieveList.price;
      }
    },
    badProductCounts: function (val) {
      if (val == 0) {
        this.total = parseInt(this.goodProductCounts);
      } else {
        this.total =
          parseInt(this.badProductCounts) + parseInt(this.goodProductCounts);
      }
    },
  },
  data() {
    return {
      defType: 4, //报工
      woCode: "", //工单编号
      woCodeShow: false, //工单弹窗
      woCodeList: [], //所有工单详细信息
      woCodeColumns: [], //所有工单code
      roleName: "", //审批人
      approvalShow: false, //审批弹窗
      approvalList: "", //所有审批人
      approvaRoleNameList: [], //所有审批人名字
      id: "", //工单id
      productCode: "", //产品编号
      productName: "", //产品名称
      reviewUserId: "", //审批人id
      wpName: "", //工序名称
      woId: "", //工序id
      wpNameShow: false, //工序弹窗
      wpNameColumns: [], //所有工序名称
      wpNameList: [], //所有工序详细信息
      wpCode: "", //工序编号
      procedureCode: "", //工序编号
      goodProductCounts: 0, //良品数
      badProductCounts: 0, //不良品数
      rwPowerOojList: [], //所有生产人员
      personnel: "", //生产人员
      personnelShow: false, //生产人员弹窗
      defectiveItemObjList: [], //所有不良品项
      defectiveText: "", //不良品项
      defectiveList: [], //选择不良品项
      defectiveShow: false, //不良品弹窗
      achieveList: [], //绩效
      totalPrice: 0, //共工资
      maxDate: new Date(),
      planStartTime: "", //开始时间
      planEndTime: "", //结束时间
      startTimeShow: false, //开始时间弹窗
      endTimeShow: false, //开始时间弹窗
      total: 0,
      badItem: [],
      editRowInfo: [],
      indexInfo: null,
    };
  },
  created() {
    ChangePageTitle("新建报工");
    this.getSysUserList();
    this.getEwoWorkOrderList();
    this.getSignature();
  },
  methods: {
    // 新增模板
    addTemplate() {
      this.editRowInfo.push({
        index: null,
        badProductCounts: null,
        name: null,
        defectiveItems: null,
      });
    },
    // 移除模板
    removeTemplate(index) {
      this.editRowInfo.splice(index, 1);
    },
    onClickLeft() {
      this.$router.replace({ path: "/task" });
    },
    //微信获取token的数据
    async getSignature() {
      var ua = navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        // alert("iphone");
        var globalUrl = encodeURIComponent(location.href.split("#")[0]);
      } else if (/android/.test(ua)) {
        // alert("android");
        var globalUrl = window.location.href.split("#")[0];
      }
      var globalUrl = window.location.href.split("#")[0];

      // console.log("globalUrl.....", globalUrl);
      const result = await getSignature(globalUrl);
      // console.log("res...getSignature..", JSON.parse(JSON.stringify(result)).data);
      const res = JSON.parse(JSON.stringify(result)).data;
      // alert(res);
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.appId, // 必填，公众号的唯一标识
        timestamp: res.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.nonceStr, // 必填，生成签名的随机串
        signature: res.signature, // 必填，签名
        jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表
      });
      wx.ready(function () {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      });
      wx.error(function (res) {
        alert("出错了：" + res.errMsg); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
      });
    },
    getUrl(data) {
      if(data.indexOf('?') != -1) {
        const q = {};
      data.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (q[k] = v));
      return q.woCode
      }else {
        return data
      }
    },
    //扫码
    scanCode() {
      let url = '1112233'
      let url1 = 'http://192.168.10.187:8080/approval?woCode=12'
      console.log(this.getUrl(url1))
      // const q = {};
      // url.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (q[k] = v));
      // console.log(q);
      var _that = this;
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          // _that.woCode = res.resultStr;
          _that.getWorkProductName(res.resultStr);
          console.log("扫一扫");
          this.$router.push({ name: "approval" });
          // console.log(res.resultStr,1111);
        },
        error: function (res) {
          if (res.errMsg.indexOf("function_not_exist") > 0) {
            alert("版本过低请升级");
          }
        },
      });
    },
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.page = this.page + 1;
        this.getListData();
        done();
      }, 200);
    },
    //扫码成功之后根据code获取产品名称
    async getWorkProductName(resultStr) {
      // if (resultStr) {
      //   _that.getPageList(resultStr);
      //   var params = {
      //     departmentCode: _that["departmentCode"],
      //     woStateList: [1],
      //   };
      //   const res = await getEwoWorkOrderList(params);
      //   let data = res;
      //   let data = _that.woCodeColumns;
      //   const index = data.findIndex((v) => v.woCode == resultStr);
      //   if (index < 0) {
      //     this.$toast("未查询到该工单");
      //     return;
      //   }
      //   _that.woCode = resultStr;
      //   _that.productCode = data[index].productCode;
      //   _that.productName = data[index].productName;
      // }
      // const q = {};
      // resultStr.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (q[k] = v));
      // console.log(q.woCode);

      this.onWoCodeConfirm(this.getUrl(resultStr));
    },
    //查询所有工单
    async getEwoWorkOrderList() {
      var params = {
        departmentCode: this["departmentCode"],
        woStateList: [1],
      };
      const res = await getEwoWorkOrderList(params);
      this.woCodeList = res;
      res.forEach((v) => {
        this.woCodeColumns.push(v.woCode);
      });
    },
    //获取用户列表 根据标签code（以及所属企业）
    async getSysUserList() {
      const params = {
        departmentCode: this["departmentCode"],
        labelCode: "project_person",
      };
      const res = await getSysUserList(params);
      res.forEach((v) => {
        this.approvaRoleNameList.push(v.nickname);
      });
      this.approvalList = res;
    },
    //查询工单工序
    async getPageList(woCode) {
      const params = {
        departmentCode: this["departmentCode"],
        woCode: woCode,
      };
      const res = await getProcedureList(params);
      this.wpNameColumns = [];
      res.forEach((v) => {
        this.wpNameColumns.push(v.wpName);
      });
      this.wpNameList = [];
      this.wpNameList = res;
    },
    //点击审批人员确定按钮
    onApprovalConfirm(roleName) {
      const index = this.approvalList.findIndex((v) => v.nickname == roleName);
      this.roleName = roleName;
      this.reviewUserId = this.approvalList[index].id;
      this.approvalShow = false;
    },
    //点击工单编号确定按钮
    onWoCodeConfirm(woCode) {
      
      this.woCodeShow = false;
      this.woCode = woCode;
      const index = this.woCodeList.findIndex((v) => v.woCode == woCode);
      if (index < 0) {
        this.$toast("未查询到该工单");
        return;
      }
      this.id = this.woCodeList[index].id;
      this.productName = this.woCodeList[index].productName;
      this.productCode = this.woCodeList[index].productCode;
      this.getPageList(woCode);
    },
    headleWpNameShow() {
      if (this.woCode == "") {
        this.$toast("请选择工单编号");
        return;
      } else {
        this.wpNameShow = true;
      }
    },
    headleItemDefinePersonnel() {
      if (this.woCode == "") {
        this.$toast("请选择工单编号");
        return;
      } else if (this.wpName == "") {
        this.$toast("请选择工序名称");
      } else {
        this.personnelShow = true;
      }
    },
    headleItemDefective(index) {
      this.indexInfo = index;
      if (this.woCode == "") {
        this.$toast("请选择工单编号");
        return;
      } else if (this.wpName == "") {
        this.$toast("请选择工序名称");
        return;
      } else {
        this.defectiveShow = true;
      }
    },
    selectDefective(value) {
      let index = this.indexInfo;
      this.editRowInfo[index].name = value.defectiveName;
      this.editRowInfo[index].defectiveItems = value.id;
      this.defectiveShow = false;
    },
    //选择时间
    selectStartTime(v) {
      this.planStartTime = this.formatter(v);
      this.startTimeShow = false;
    },
    selectEndTime(v) {
      this.planEndTime = this.formatter(v);
      this.endTimeShow = false;
    },
    formatter(val) {
      let year = val.getFullYear(); //年
      let month = val.getMonth() + 1; //月
      let day = val.getDate(); //日
      let hours = val.getHours(); //小时
      let minutes = val.getMinutes(); //分钟
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (hours >= 0 && hours <= 9) {
        hours = `0${hours}`;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = `0${minutes}`;
      }
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    //点击工序名称确定按钮
    onWpNameConfirm(wpName) {
      const index = this.wpNameList.findIndex((v) => v.wpName == wpName);
      this.rwPowerOojList = this.wpNameList[index].rwPowerOojList;
      this.defectiveItemObjList = this.wpNameList[index].defectiveItemObjList;
      this.wpNameShow = false;
      this.woId = this.wpNameList[index].id;
      this.wpCode = this.wpNameList[index].wpCode;
      this.wpName = wpName;
      this.procedureCode = this.wpNameList[index].wpCode;
    },
    //查询绩效
    async getEwoAchieveConfigParam() {
      if (this.productCode == "") {
        this.$toast("请选择工单编号");
        return;
      }
      if (this.procedureCode == "") {
        this.$toast("请选择工序名称");
        return;
      }
      var params = {
        departmentCode: this["departmentCode"],
        productCode: this.productCode,
        procedureCode: this.procedureCode,
      };
      //查询报工所需的绩效模型
      const res = await getEwoAchieveConfigParam(params);
      if (res == null) {
        this.$toast("此工单暂无绩效");
        return;
      } else {
        this.achieveList = res;
      }
    },
    headleItemRefresh() {
      this.getEwoAchieveConfigParam();
    },
    async onSubmit() {
      try {
        var _that = this;
        if (_that.productCode == "") {
          _that.$toast("请选择工单编号");
          return;
        }
        if (_that.procedureCode == "") {
          _that.$toast("请选择工序名称");
          return;
        }
        //处理生产人员
        const index = _that.rwPowerOojList.findIndex(
          (v) => v.nickname == _that.personnel
        );
        const res = await _that.$refs.customise.onModulSubmit();
        if (this.editRowInfo.length > 0) {
          this.badItem = this.editRowInfo.filter(
            (i) => i.badProductCounts && i.defectiveItems
          );
        }
        var params1 = {
          woId: _that.woId,
          woCode: _that.woCode,
          wpCode: _that.wpCode,
          defectiveItemsA: this.badItem,
          badItem: this.badItem,
          departmentCode: this["departmentCode"],
          rwPowerId: _that.rwPowerOojList[index].id,
          goodProductCounts: _that.goodProductCounts,
          badProductCounts: _that.badProductCounts,
          // defectiveItems: defectiveIdList.toString() || "",
          startTime: _that.planStartTime,
          endTime: _that.planEndTime,
          reviewState: _that.reviewUserId == "" ? 0 : 1, //新增的工单未审核为0
          wages: _that.totalPrice == "" ? 0 : _that.totalPrice,
          type: _that.achieveList.type == "" ? 0 : _that.achieveList.type,
          price: _that.achieveList.price == "" ? 0 : _that.achieveList.price,
          reviewUserId: _that.reviewUserId,
          definitionProperties: res,
        };
        await addWork(params1);
        _that.$toast("添加成功");
        _that.$router.push("/task");
      } catch (err) {
        console.log(err);
      }
    },
  },
  components: {
    scroll,
    customise,
  },
};
</script>
<style lang="less" scoped>
.report_work {
  width: 100vw;
  height: 100vh;

  .report_work_scroll {
    height: 100vh;

    .report_work_from {
      .report_work_from_header {
        width: 95%;
        margin: auto;
        margin-top: 40px;
        border-radius: 5px;

        /deep/ .van-field__right-icon {
          .van-icon-scan {
            color: blue;
          }
        }
      }
    }

    .report_work_input {
      width: 95%;
      margin: auto;
      margin-top: 40px;
      font-size: 30px;
    }

    .report_work_input_details {
      width: 95%;
      margin: auto;
      margin-top: 40px;

      .report_work_personnel {
        margin-bottom: 10px;
        border-radius: 5px;
      }

      .report_work_numb {
        border-radius: 5px;

        .div-bad {
          font-size: 26px;
          margin: 3% auto;

          .bad-item {
            margin: 2% 0px;
            background-color: #f5f5f5;
            text-align: center;
          }

          .col-pad {
            padding-top: 5%;
          }

          .col-info {
            text-align: center;
          }

          .icon-div-info {
            color: #1989fa;
            margin-top: 1.5%;
            margin-left: 3%;

            .icon-two {
              margin-top: 10%;
            }

            .icon-add-text {
              margin-left: -15%;
            }
          }
        }
      }
    }

    .report_work_input_time {
      width: 95%;
      margin: auto;
      margin-top: 20px;
      border-radius: 5px;

      /deep/ .icon-bitian {
        color: red;
      }
    }

    .report_work_achievements {
      width: 95%;
      margin: auto;
      margin-top: 40px;
      font-size: 30px;
      margin-bottom: 20px;

      .report_work_achievements_item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .refresh {
          color: blue;
          display: flex;
          align-items: center;

          .icon {
            font-size: 40px;
          }
        }
      }
    }

    .report_work_calculation {
      width: 95%;
      margin: auto;
      border-radius: 5px;
      background-color: #fff;
      padding: 15px 15px;
      font-size: 30px;
      box-sizing: border-box;

      .report_work_wages {
        display: flex;
        align-items: center;

        .icon {
          font-size: 40px;
          color: blue;
        }
      }

      .report_work_total {
        margin-top: 15px;

        .report_work_total_yuan {
          color: blue;
        }
      }
    }

    .report_work_approval {
      width: 95%;
      margin: auto;
      border-radius: 5px;
      background-color: #fff;
      margin-top: 20px;
    }
  }

  .color {
    width: 90vw;
    margin: auto;
    border-radius: 5px;
    margin-top: 15px !important;
  }

  .submit {
    margin-top: 40px !important;
  }

  .jurisdiction {
    padding: 20px;

    .jurisdiction_headle {
      font-size: 30px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      div:nth-child(2) {
        color: #576b95;
      }
    }

    .van-radio {
      margin-top: 20px;
      font-size: 30px;
    }

    .van-checkbox {
      margin-top: 20px;
      font-size: 30px;
    }
  }
}
</style>
